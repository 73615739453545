<button
  class="button button_{{ type }} button_{{ size }}"
  [class.button_disabled]="disabled"
  [class.button_full-width]="fullWidth"
  [class.expocab-button-big]="size === 'big'"
  [class.expocab-button-small]="size === 'small' || size === 'filter-big'"
  [type]="htmlType"
  [disabled]="disabled"
>
  @if (icon) {
    <svg-icon class="button__icon" [src]="icon | svgPath" />
  }
  <span> {{ text }} </span>
</button>
