import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  booleanAttribute,
} from '@angular/core';
import { SvgPathPipe } from '@expocab/shared/libs';
import { SvgIconComponent } from 'angular-svg-icon';

type ButtonSize = 'big' | 'filter-big' | 'small';
type ButtonType =
  | 'primary'
  | 'secondary'
  | 'transparent'
  | 'white'
  | 'white-filter';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgPathPipe, SvgIconComponent],
  selector: 'expocab-button',
  standalone: true,
  styleUrl: './button.component.scss',
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  @Input({ transform: booleanAttribute }) disabled!: boolean;

  @HostBinding('class.w100')
  @Input({ transform: booleanAttribute })
  fullWidth!: boolean;

  @Input() htmlType: 'button' | 'reset' | 'submit' = 'button';
  @Input() icon!: string;
  @Input({ required: true }) size: ButtonSize = 'big';
  @Input({ required: true }) text!: string;
  @Input({ required: true }) type!: ButtonType;
}
